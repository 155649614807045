import React, { useContext, useEffect, useState, useRef } from "react";
import { Nav, Badge } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";

import { Bar } from "react-chartjs-2";

import pattern1 from "../../../images/pattern/pattern1.png";
import pattern2 from "../../../images/pattern/pattern2.png";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import LifetimeAlert from '../LifetimeAlert/LifetimeAlert';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const Dashboard = () => {
  const canvasRef = useRef(null);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [endX, setEndX] = useState(null);
  const [endY, setEndY] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#FF0000');
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.src = './'; // Replace with your image path
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      setImageLoaded(true);
    };
  }, []);

  const handleMouseDown = (e) => {
    if (!imageLoaded) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setStartX(x);
    setStartY(y);
    setEndX(x); // Initialize endX and endY
    setEndY(y);
    setIsDrawing(true);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing || !imageLoaded) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setEndX(x);
    setEndY(y);

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Redraw the image
    const image = new Image();
    image.src = './house.jpeg'; // Replace with your image path
    image.onload = () => {
      ctx.drawImage(image, 0, 0);

      // Draw the selection rectangle
      ctx.beginPath();
      ctx.rect(startX, startY, x - startX, y - startY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = 'red';
      ctx.stroke();
    };
  };

  const handleMouseUp = () => {
    if (!isDrawing || !imageLoaded) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    const x0 = Math.min(startX, endX);
    const y0 = Math.min(startY, endY);
    const x1 = Math.max(startX, endX);
    const y1 = Math.max(startY, endY);

    // Example: Change color inside the selected rectangle
    ctx.beginPath();
    ctx.rect(startX, startY, endX - startX, endY - startY);
    ctx.clip(); // Clip to the selection rectangle

    for (let y = y0; y < y1; y++) {
      for (let x = x0; x < x1; x++) {
        const index = (y * canvas.width + x) * 4;
        data[index] = parseInt(selectedColor.slice(1, 3), 16); // R
        data[index + 1] = parseInt(selectedColor.slice(3, 5), 16); // G
        data[index + 2] = parseInt(selectedColor.slice(5, 7), 16); // B
      }
    }

    ctx.putImageData(imageData, 0, 0);
    setIsDrawing(false);
  };

  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
  };

  return (
    <div>
      <canvas
        ref={canvasRef}
        width={800}
        height={600}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      ></canvas>
      <select onChange={handleColorChange} value={selectedColor}>
        <option value="#FF0000">Red</option>
        <option value="#00FF00">Green</option>
        <option value="#0000FF">Blue</option>
      </select>
    </div>
  );
};

export default Dashboard;
