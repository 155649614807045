import React, { useContext, useEffect, useState, useRef } from "react";
import { Dropdown, Nav, Badge } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";

import { Bar } from "react-chartjs-2";

import pattern1 from "../../../images/pattern/pattern1.png";
import pattern2 from "../../../images/pattern/pattern2.png";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import LifetimeAlert from '../LifetimeAlert/LifetimeAlert';
import SubscribeAlert from './SubscribeAlert';
import RecentClosedPositions from './RecentClosedPositions';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const Dashboard = () => {
  const [totalProfit, setTotalProfit] = useState(0);
  const [, setTotalVolume] = useState(0);
  // const [totalInvested, setTotalInvested] = useState(0);
  const [netProfitPercent, setNetProfitPercent] = useState(0);
  const [tradingCapital, setTradingCapital] = useState(0);
  
  const [activeChart, setActiveChart] = useState('week');

  const [chartDataValues, setChartDataValues] = useState([]);
  const [chartDataPercentValues, setChartDataPercentValues] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [loading, setLoading] = useState(true);

  const [userIntegrations, setUserIntegrations] = useState([]);
  const [activeNameIntegration, setActiveNameIntegration] = useState("");
  const [activeIntegrationId, setActiveIntegrationId] = useState(0);
  // const [activeIntegrationPlatform, setActiveIntegrationPlatform] = useState("");
  const activeIntegrationIdRef = useRef(0);
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getMainStatistic();
    checkUserAuth();

    SetBackgroundTheme(changeBackground, Cookies);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line
  }, [activeChart]);

  useEffect(() => {
    getMainStatistic();
    // eslint-disable-next-line
  }, [activeIntegrationId]);

  let path = window.location.pathname;
  path = path.split("/");
  var location = path[path.length - 1];
  location = location.split("?")[0];
  
  async function checkUserAuth() {
    const token = Cookies.get('token');
    const data = { token: token }
    try{
      const response = await axios.post(baseURL+'/api/user/auth_check', data)
      if(response.data.status === 'ok') {
          Cookies.set('token', response.data.token, { expires: 1, path: '/'});
          Cookies.set('subscribe', response.data.subscribe, { expires: 1, path: '/'});

          if(location !== 'page-confirm-email' && location !== 'page-confirm-email-checking') {
            if(response.data.email_verified_at === null) {
              window.location.href = '/page-confirm-email';
              return "";
            }
          }

          if(location === 'page-login' || location === 'page-register' || location === 'page-forgot-password') {
            window.location.href = '/';
          }
      } else {
          if(location !== 'page-login' && location !== 'page-register' && location !== 'page-forgot-password' && location !== 'page-confirm-email' && location !== 'page-confirm-email-checking' && location !== 'page-password-reset') {
            window.location.href = '/page-login';
          }
      }
    } catch(e) {
      await delay(2000);
      await checkUserAuth();
    }
  }

  async function getMainStatistic() {
    setLoading(true);
    const data = {
      token: Cookies.get('token'),
      integration_id: activeIntegrationIdRef.current
    }

    try {
      const response = await axios.post(baseURL+'/api/dashboard/get_main_statistics', data);
      console.log("Main Statistic: ", response.data)
      if(response.data.status === 'ok') {
        setTotalProfit(response.data.net_profit);
        setTotalVolume(response.data.volume);
        // setTotalInvested(response.data.total_invested);
        setNetProfitPercent(response.data.net_profit_percent);
        setTradingCapital(response.data.trading_capital);

        setUserIntegrations(response.data.users_integrations);
        setActiveNameIntegration(response.data.integration_id_name);
        setActiveIntegrationId(response.data.integration_id);
        // setActiveIntegrationPlatform(response.data.platform);
        activeIntegrationIdRef.current = response.data.integration_id

        await getChartData();
      } else {
        // setError(response.data.text);
      }
    } catch(err) {
      await delay(2000);
      await getMainStatistic();
    }
    setLoading(false);
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  async function getChartData() {
    const data = {
      token: Cookies.get('token'),
      chart_type: activeChart,
      integration_id: activeIntegrationIdRef.current
    }

    try {
      const response = await axios.post(baseURL+'/api/dashboard/get_chart_data', data);
      if(response.data.status === 'ok') {
        setChartDataValues(response.data.data_values);
        setChartDataPercentValues(response.data.data_percent_values);
        setChartLabels(response.data.labels);
      } else {
        // setError(response.data.text);
      }
    } catch(e) {
      await delay(2000);
      await getChartData();
    }
  }

  const chartData = {
    defaultFontFamily: "poppins",
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    labels: chartLabels,
    datasets: [
      {
        yAxisID: 'first',
        label: "USDT",
        // data: [65, -59, 80, -81, 56, -55, 40, -55, 40],
        data: chartDataValues,
        // borderColor: "rgba(64, 24, 157, 0)",
        borderWidth: "0",
        backgroundColor: setBarColor,
        barPercentage: 0.5,
      },
      {
        yAxisID: 'second',
        label: "%",
        // data: [3.75, -1, 2, -3, 4, -5, 6, -7, 7],
        data: chartDataPercentValues,
        // borderColor: "rgba(64, 24, 157, 0)",
        borderWidth: "0",
        backgroundColor: '#00a8ff',
        barPercentage: 0.5,
      },
    ]
  };

  function setBarColor(value) {
    if (value.dataset.data[value.dataIndex] > 0) {
      return "#61C277";
    } else {
      return "#FF3E3E";
    }
  }

  const chartOptions = {
    legend: false,
    scales: {
      yAxes: [
        {
          id: 'first',
          ticks: {
            display: true,
            beginAtZero: true,
            callback: function(value, index, values) {
              // Add label before each value
              return '$ ' + value;
            },
            // suggestedMin: Math.min(...chartDataValues, ...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataValues, ...chartDataPercentValues)
            suggestedMin: getSuggestedMin(chartDataValues),
            suggestedMax: getSuggestedMax(chartDataValues),

          },
          gridLines: {
            display: false
          },
          position: 'left',
        },
        {
          id: 'second',
          ticks: {
            display: true,
            beginAtZero: true,
            callback: function(value, index, values) {
              // Add label before each value
              return value + '%';
            },
            // suggestedMin: Math.min(...chartDataValues, ...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataValues, ...chartDataPercentValues)
            // suggestedMin: Math.min(...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataPercentValues)
            suggestedMin: getSuggestedMin(chartDataPercentValues),
            suggestedMax: getSuggestedMax(chartDataPercentValues),
          },
          gridLines: {
            display: false
          },
          position: 'right',
        },
      ],
      xAxes: [
        {
          // Change here
          barPercentage: 0.5,
          ticks: {
            display: true,
            beginAtZero: true,
          },
          gridLines: {
            display: false
          },
        },
      ],
    },
  };

  function getSuggestedMin(data) {
    const min = Math.min(...data);
    const max = Math.max(...data);

    const min_abs = Math.abs(min);
    const max_abs = Math.abs(max);

    var result_min = 0;
    if(min_abs > max_abs) {
      result_min = min_abs;
    } else {
      result_min = max_abs;
    }

    if(min < 0) {
      result_min = result_min * (-1)
    }

    return result_min;
  }

  function getSuggestedMax(data) {
    const min = Math.min(...data);
    const max = Math.max(...data);

    const min_abs = Math.abs(min);
    const max_abs = Math.abs(max);

    var result_max = 0;
    if(min_abs > max_abs) {
      result_max = min_abs;
    } else {
      result_max = max_abs;
    }

    return result_max;
  }

  return (
    <div className="row">
      {/* <SetBackgroundTheme /> */}

      <LifetimeAlert />
      <SubscribeAlert />

      {loading === false && (
        <div className="col-12" style={{zIndex: 2}}>
          <div className="d-flex align-items-center flex-wrap mb-3">
            <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3 mr-3">
              <Dropdown.Toggle
                variant=""
                type="button"
                className="btn dropdown-toggle btn-light"
                data-toggle="dropdown"
                role="button"
                title="Latest"
                aria-expanded="false"
              >
                {activeNameIntegration}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu"
                role="combobox"
                x-placement="bottom-start"
                style={{
                  maxHeight: 174,
                  overflow: "hidden",
                  minHeight: 0,
                  position: "absolute",
                  willChange: "transform",
                  top: 0,
                  left: 0,
                  transform: "translate3d(0px, 41px, 0px)",
                }}
              >
                
                {userIntegrations && userIntegrations.map((item, key) => (
                  <Dropdown.Item key={`user_integration_${key}`} onClick={() => {setActiveNameIntegration(item.name); setActiveIntegrationId(item.id); activeIntegrationIdRef.current = item.id; }}>
                    {item.name} (Platform: {item.platform})
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}

      {loading === true && (
        <div className="col-12">
          <Badge variant="info light">Loading...</Badge>
        </div>
      )}

      {loading === false && (
        <>
          <div className="col-12 p-0 mb-4">
            <div className="row">
              <div className="col-12 col-sm-6 mb-2">
                <div className="items">
                  <div
                    className="wallet-card bg-info"
                    style={{ backgroundImage: `url(${pattern1})` }}
                  >
                    <div className="head">
                      <p className="fs-14 text-white mb-0 op6 font-w100">Total Profit, USDT</p>
                      <span>
                        ${totalProfit}
                        <small className={netProfitPercent >= 0 ? "fs-16 pl-3 text-success" : "fs-16 pl-3 text-warning"}>
                          {netProfitPercent}%
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-2">
                <div className="items">
                  <div
                    className="wallet-card bg-primary"
                    style={{ backgroundImage: `url(${pattern2})` }}
                  >
                    <div className="head">
                      <p className="fs-14 text-white mb-0 op6 font-w100">Estimated Trading Capital, USDT</p>
                      <span>${tradingCapital}</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-sm-6 mb-2">
                <div className="items">
                  <div
                    className="wallet-card bg-primary"
                    style={{ backgroundImage: `url(${pattern2})` }}
                  >
                    <div className="head">
                      <p className="fs-14 text-white mb-0 op6 font-w100">Total Invested, USDT</p>
                      <span>${totalInvested}</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          
          {totalProfit !== 0 && (
          <div className="col-12">
            <div className="card overflow-hidden">
              <div className="card-header d-block d-sm-flex border-0">
                <div>
                  <h4 className="fs-20 text-black">PnL, USDT</h4>
                  <p className="mb-0 fs-12">
                    The Breakdown of Profit
                  </p>
                </div>
                <div className="card-action card-tabs mt-3 mt-sm-0">
                  <Nav as="ul" className="nav nav-tabs" role="tablist">
                    <Nav.Item as="li" className="nav-item">
                      <Nav.Link
                        as="a"
                        className={activeChart === "week" ? "nav-link c-pointer active" : "nav-link c-pointer"}
                        data-toggle="tab"
                        eventKey="Week"
                        role="tab"
                        onClick={() => setActiveChart("week")}
                      >
                        Week
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="nav-item">
                      <Nav.Link
                        as="a"
                        className={activeChart === "month" ? "nav-link c-pointer active" : "nav-link c-pointer"}
                        data-toggle="tab"
                        eventKey="Month"
                        role="tab"
                        onClick={() => setActiveChart("month")}
                      >
                        Month
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <div className="card-body">
                <div className="d-block d-sm-none">
                  <Bar data={chartData} height={250} options={chartOptions} />
                </div>
                <div className="d-none d-sm-block">
                  <Bar data={chartData} height={100} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>
          )}

          {totalProfit === 0 && (
            <RecentClosedPositions />
          )}

        </>
      )}

    </div>
  );
};

export default Dashboard;
