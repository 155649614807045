import React, { useState, useEffect, useContext, useRef } from "react";
import { Dropdown, Badge, Popover, OverlayTrigger, Button, Pagination, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import LifetimeAlert from '../LifetimeAlert/LifetimeAlert';

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

import TradingViewChart from './TradingViewChart';

const Statistics = () => {
  // const chartData = [
  //   { time: '2024-07-01', open: 100, high: 110, low: 95, close: 105 },
  //   { time: '2024-07-02', open: 105, high: 115, low: 100, close: 110 },
  //   { time: '2024-07-03', open: 110, high: 115, low: 80, close: 89 },
  // ];

  // const buyPoints = [
  //   { time: '2024-07-20', price: 150 },
  //   // Add more buy points
  // ];
  
  // const sellPoints = [
  //   { time: '2024-07-22', price: 160 },
  //   // Add more sell points
  // ];

  const [charts, setCharts] = useState([
    // {
    //   id: 1,
    //   pair: 'BTCUSDT',
    //   startDate: '2024-07-23 10:54:06',
    //   endDate: '2024-07-23 10:54:06',
    //   timeFrame: '15m',
    //   chartData: chartData,
    //   buyPoints: buyPoints,
    //   sellPoints: sellPoints,
    // },
    // {
    //   id: 2,
    //   pair: 'ETHUSDT',
    //   startDate: '2024-07-23 10:54:06',
    //   endDate: '2024-07-23 10:54:06',
    //   timeFrame: '15m',
    //   chartData: chartData,
    //   buyPoints: buyPoints,
    //   sellPoints: sellPoints,
    // }
  ]);

  function deleteChart(id) {
    var tmp_charts = [];
    for(const c of charts) {
      if(c.id !== id) {
        tmp_charts.push(c);
      }
    }
    setCharts([...tmp_charts]);
  }

  async function addNewChart(item, position_type) {
    setLoadingChart(true);
    var tmp_charts = charts;


    var timeFrame = '15m';

    if(position_type === 'closed') {
      const timestamp_compare = parseInt(item.exit_timestamp) - parseInt(item.entry_timestamp);
      if(timestamp_compare >= (86400 * 30)) {
        timeFrame = '1d';
      }
      else if(timestamp_compare >= (14400 * 30)) {
        timeFrame = '4h';
      }
      else if(timestamp_compare >= (3600 * 30)) {
        timeFrame = '1h';
      }
      else if(timestamp_compare >= (900 * 30)) {
        timeFrame = '15m';
      }
    } else {
      timeFrame = '15m';
    }

    const chartDataResponse = await getChartData(item.id, timeFrame);

    const endDate = item.exit_timestamp !== null ? convertTimestampToYmdHisUTC(item.exit_timestamp) : null;

    const new_chart = {
      id: item.id,
      pair: item.pair,
      startDate: convertTimestampToYmdHisUTC(item.entry_timestamp),
      endDate: endDate,
      timeFrame: timeFrame,
      chartData: chartDataResponse.chartData,
      buyPoints: chartDataResponse.buyPoints,
      sellPoints: chartDataResponse.sellPoints,
      priceMin: chartDataResponse.priceMin,
      priceRoundNumber: chartDataResponse.priceRoundNumber
    }
    tmp_charts.push(new_chart)
    setCharts([...tmp_charts]);

    // const tmp_charts_element_number = tmp_charts.length === 1 ? 0 : tmp_charts.length - 2;
    const tmp_charts_element_number = tmp_charts.length - 1;
    const id_to_scroll = "#chart_" + tmp_charts[tmp_charts_element_number].id;
    scrollToElement(id_to_scroll);
    setLoadingChart(false);
  }

  // const scrollToElement = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  // const scrollToElement = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     const elementPosition = element.getBoundingClientRect().top;
  //     const offsetPosition = elementPosition - 100;

  //     window.scrollTo({
  //       top: offsetPosition,
  //       behavior: 'smooth'
  //     });
  //   }
  // };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetPosition = element.offsetTop - 100;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  async function changeChartTimeFrame(item, time_frame) {
    setChangingTimeFrame(true);
    var tmp_charts = charts;

    const chartDataResponse = await getChartData(item.id, time_frame);

    for(const c of tmp_charts) {
      if(c.id === item.id) {
        c.timeFrame = time_frame;
        c.chartData = chartDataResponse.chartData;
        c.buyPoints = chartDataResponse.buyPoints;
        c.sellPoints = chartDataResponse.sellPoints;
        c.priceMin = chartDataResponse.priceMin;
        c.priceRoundNumber = chartDataResponse.priceRoundNumber;
      }
    }

    setCharts([...tmp_charts]);
    setChangingTimeFrame(false);
  }

  async function getChartData(id, timeFrame) {
    const data = {
      token: Cookies.get('token'),
      position_id: id,
      time_frame: timeFrame
    }

    try {
      const response = await axios.post(baseURL+'/api/positions/get_chart_data', data);
      if(response.data.status === 'ok') {
        return {
          status: "ok",
          chartData: response.data.chartData,
          buyPoints: response.data.buyPoints,
          sellPoints: response.data.sellPoints,
          priceMin: response.data.priceMin,
          priceRoundNumber: response.data.priceRoundNumber,
        }
      } else {
        // setError(response.data.text);
      }
    } catch(err) {}
    
  }


  const [activeName, setActiveName] = useState("Closed Positions");
  const [activaTableName, setActivaTableName] = useState('closed');
  const [closedPositions, setClosedPositions] = useState([]);
  const [openPositions, setOpenPositions] = useState([]);

  const [userIntegrations, setUserIntegrations] = useState([]);
  const [activeNameIntegration, setActiveNameIntegration] = useState("");
  const [activeIntegrationId, setActiveIntegrationId] = useState(0);
  const [activeIntegrationPlatform, setActiveIntegrationPlatform] = useState("");
  const activeIntegrationIdRef = useRef(0);
  

  const [loadingClosedPositions, setLoadingClosedPositions] = useState(true);
  const [loadingOpenPositions, setLoadingOpenPositions] = useState(true);
  

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  const [themeName, setThemeName] = useState('light');
  const [changingTimeFrame, setChangingTimeFrame] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [timezone, setTimezone] = useState('');
  

  const { changeBackground, background } = useContext(ThemeContext);
  useEffect(() => {
    const offset = getTimezoneOffsetInHours();
    const timezone_in_utc = `UTC${offset >= 0 ? '+' : ''}${offset}`
    setTimezone(timezone_in_utc);

    getClosedPositions(1);
    getOpenPositions();
    SetBackgroundTheme(changeBackground, Cookies);

    // Set interval to run the function every minute
    const intervalId = setInterval(getOpenPositions, 10000); // 60000 milliseconds = 1 minute
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setThemeName(background.value);
  }, [background]);

  useEffect(() => {
    getClosedPositions(1);
    getOpenPositions();
  }, [activeIntegrationId]);

  

  

  function setNavigationLinks(max_page) {
    var page_items_tmp = [];
    var index = 0;
    for (let number = active; number <= max_page; number++) {
        index += 1;
        if(index >= 7) {
          break;
        }
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    var index = 0;
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
        index += 1;
        if(index >= 7) {
          break;
        }
    }
    setPageItems(page_items_tmp);
    getClosedPositions(page_number);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    var index = 0;
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
        index += 1;
        if(index >= 7) {
          break;
        }
    }
    setPageItems(page_items_tmp);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  async function getClosedPositions(page_number) {
    setLoadingBadge(true);
    const data = {
      token: Cookies.get('token'),
      page: page_number,
      integration_id: activeIntegrationId
    }

    try {
      const response = await axios.post(baseURL+'/api/positions/get_closed', data);
      if(response.data.status === 'ok') {
        setClosedPositions(response.data.positions);
        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
        setUserIntegrations(response.data.users_integrations);

        setActiveNameIntegration(response.data.integration_id_name);
        setActiveIntegrationId(response.data.integration_id);
        setActiveIntegrationPlatform(response.data.platform);
        activeIntegrationIdRef.current = response.data.integration_id

      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setLoadingBadge(false);
    setLoadingClosedPositions(false);
  }

  async function getOpenPositions() {
    const data = {
      token: Cookies.get('token'),
      integration_id: activeIntegrationIdRef.current
    }

    try {
      const response = await axios.post(baseURL+'/api/positions/get_open', data);
      if(response.data.status === 'ok') {
        setOpenPositions(response.data.positions);
        
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }

    setLoadingOpenPositions(false);
  }

  const getTimezoneOffsetInHours = () => {
    const offset = new Date().getTimezoneOffset();
    const offset_hour = -offset / 60;
    // console.log("offset_hour: ", offset_hour);
    return -offset / 60; // Convert minutes to hours
  };

  const getTimezoneName = () => {
    const dateTimeFormat = new Intl.DateTimeFormat();
    const options = dateTimeFormat.resolvedOptions();
    return options.timeZone; // Returns the IANA timezone string, e.g., "America/Chicago"
  };

  function convertTimestampToYmdHis(timestamp) {
    const offset = getTimezoneOffsetInHours();
    const timezone_in_utc = `UTC${offset >= 0 ? '+' : ''}${offset}`
    const timezone_name = getTimezoneName();

    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone_name
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;

    // Convert timestamp to Date object
    // let date = new Date(timestamp * 1000);

    // // Get the year, month, day, hours, minutes, and seconds
    // let year = date.getFullYear();
    // let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    // let day = String(date.getDate()).padStart(2, '0');
    // let hours = String(date.getHours()).padStart(2, '0');
    // let minutes = String(date.getMinutes()).padStart(2, '0');
    // let seconds = String(date.getSeconds()).padStart(2, '0');

    // // Construct the formatted date string
    // let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    // return formattedDate;
  };

  function convertTimestampToYmdHisUTC(timestamp) {
    // Create a new Date object from the timestamp
    // const date = new Date(timestamp * 1000);

    // // Extract the date components
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);
    // const hours = ('0' + date.getHours()).slice(-2);
    // const minutes = ('0' + date.getMinutes()).slice(-2);
    // const seconds = ('0' + date.getSeconds()).slice(-2);

    // // Construct the formatted date string
    // const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    // return formattedDate;

    // Convert timestamp to Date object
    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC' 
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  return (
    <>

      <div className="row">
        <LifetimeAlert />
      </div>

      <div className="row">

      {charts &&
        charts.map((item, key) => (
          <div key={key} id={`#chart_${item.id}`} className="col-12 col-md-6">
            <div className="card p-3">

              <div className="" style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                <div>
                  <h4 className="fs-20 text-black">{item.pair}</h4>
                  <h5 className="fs-14 text-black">
                    Start Date (UTC): {item.startDate}<br/>
                    {item.endDate !== null && (
                      <span>End Date (UTC): {item.endDate}</span>
                    )}
                  </h5>
                </div>
                
                <Button variant="" className="close fs-20" onClick={() => deleteChart(item.id)}>
                  <span>&times;</span>
                </Button>

                
              </div>

              <hr/>
              

              <div className="row pl-3 pr-3">
                {/* <div className={item.timeFrame === '1m' ? "mr-2 text-warning" : "mr-2 text-black"} style={{cursor: 'pointer'}} onClick={() => changeChartTimeFrame(item, '1m')}><u>1m</u></div>  */}
                <div className={item.timeFrame === '15m' ? "mr-2 text-warning" : "mr-2 text-black"} style={{cursor: 'pointer'}} onClick={() => changeChartTimeFrame(item, '15m')}><u>15m</u></div> 
                <div className={item.timeFrame === '1h' ? "mr-2 text-warning" : "mr-2 text-black"} style={{cursor: 'pointer'}} onClick={() => changeChartTimeFrame(item, '1h')}><u>1h</u></div> 
                <div className={item.timeFrame === '4h' ? "mr-2 text-warning" : "mr-2 text-black"} style={{cursor: 'pointer'}} onClick={() => changeChartTimeFrame(item, '4h')}><u>4h</u></div> 
                <div className={item.timeFrame === '1d' ? "mr-2 text-warning" : "mr-2 text-black"} style={{cursor: 'pointer'}} onClick={() => changeChartTimeFrame(item, '1d')}><u>1d</u></div> 
              </div>

              <div style={{opacity: changingTimeFrame === true ? 0.4 : 1}}>
                {themeName === 'light' && (
                  <TradingViewChart buyPoints={item.buyPoints} sellPoints={item.sellPoints} chartData={item.chartData} priceMin={item.priceMin} priceRoundNumber={item.priceRoundNumber} theme='light' />
                )}
                {themeName === 'dark' && (
                  <TradingViewChart buyPoints={item.buyPoints} sellPoints={item.sellPoints} chartData={item.chartData} priceMin={item.priceMin} priceRoundNumber={item.priceRoundNumber} theme='dark' />
                )}
              </div>


            </div>
        </div>
        )
      )}
      </div>
      
      <div className="d-flex align-items-center flex-wrap mb-3">
        {/* <div className="input-group search-area-2 mb-3 mr-auto d-inline-flex">
          <div className="input-group-append">
            <a href="#" className="input-group-text">
              <i className="flaticon-381-search-2" />
            </a>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
          />
        </div> */}
        {/* <a
          href="#"
          className="btn btn-primary btn-rounded mb-3 mr-3"
        >
          <i className="las la-download scale5 mr-2" />
          Get Report
        </a>
        <a
          href="#"
          className="btn btn-outline-primary btn-rounded mb-3 mr-3 button-style"
        >
          <i className="las la-calendar scale5 mr-2" />
          Filter Periode
        </a> */}
        <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3 mr-3">
          <Dropdown.Toggle
            variant=""
            type="button"
            className="btn dropdown-toggle btn-light"
            data-toggle="dropdown"
            role="button"
            title="Latest"
            aria-expanded="false"
          >
            {activeNameIntegration}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu"
            role="combobox"
            x-placement="bottom-start"
            style={{
              maxHeight: 174,
              overflow: "hidden",
              minHeight: 0,
              position: "absolute",
              willChange: "transform",
              top: 0,
              left: 0,
              transform: "translate3d(0px, 41px, 0px)",
            }}
          >
            
            {userIntegrations && userIntegrations.map((item, key) => (
              <Dropdown.Item key={`user_integration_${key}`} onClick={() => {setActiveNameIntegration(item.name); setActiveIntegrationId(item.id); activeIntegrationIdRef.current = item.id; }}>
                {item.name} (Platform: {item.platform})
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="dropdown bootstrap-select form-control style-1 default-select mb-3">
          <Dropdown.Toggle
            variant=""
            type="button"
            className="btn dropdown-toggle btn-light"
            data-toggle="dropdown"
            role="button"
            title="Latest"
            aria-expanded="false"
          >
            {activeName}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu"
            role="combobox"
            x-placement="bottom-start"
            style={{
              maxHeight: 174,
              overflow: "hidden",
              minHeight: 0,
              position: "absolute",
              willChange: "transform",
              top: 0,
              left: 0,
              transform: "translate3d(0px, 41px, 0px)",
            }}
          >
            <Dropdown.Item onClick={() => {setActiveName("Closed Positions"); setActivaTableName('closed')}}>
              Closed Positions
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {setActiveName("Open Positions"); setActivaTableName('open')}}>
              Open Positions
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive table-hover fs-14 ">

            

            {activaTableName === 'closed' && (
              <div id="example6_wrapper" className="dataTables_wrapper no-footer">

                {loadingClosedPositions === true && (
                  <Badge variant="info light">Loading...</Badge>
                )}

                {loadingClosedPositions === false && closedPositions.length === 0 && (
                  <Alert variant="primary" className="alert-dismissible fade show">
                    There are no closed positions at this moment.
                  </Alert>
                )}
                {loadingClosedPositions === false && closedPositions.length > 0 && (
                  <>
                    <div className="d-block d-sm-none">
                    {closedPositions &&
                      closedPositions.map((item, key) => (
                        <div key={`closed_${key}`} className="card p-3 text-black">
                          <div className="row">
                            <div className="col-5">
                              <b>Start Date</b>
                            </div>
                            <div className="col-7 text-right">
                              <span>{convertTimestampToYmdHis(item.entry_timestamp)} ({timezone})</span><br/>
                              <small>{convertTimestampToYmdHisUTC(item.entry_timestamp)} (UTC)</small>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-5">
                              <b>End Date</b>
                            </div>
                            <div className="col-7 text-right">
                              <span>{convertTimestampToYmdHis(item.exit_timestamp)} ({timezone})</span><br/>
                              <small>{convertTimestampToYmdHisUTC(item.exit_timestamp)} (UTC)</small>
                            </div>
                          </div>
                          <hr/>

                          <div className="row">
                            <div className="col-5">
                              <b>Pair</b>
                            </div>
                            <div className="col-7 text-right">
                              {activeIntegrationPlatform === "binance" && (
                                <span onClick={() => addNewChart(item, 'closed')} style={{opacity: loadingChart === true ? 0.4 : 1}}>
                                  {/* <img alt="" src="./images/binance_logo.png" width="25px" /> */}
                                  <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline" style={{textDecoration: 'underline'}}>{item.pair}</a>
                                </span>
                              )}
                              {activeIntegrationPlatform === "bybit" && (
                                <span>
                                  <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline" style={{textDecoration: 'underline'}}>{item.pair}</a>
                                </span>
                              )}
                            </div>  
                          </div>

                          <div className="row">
                            <div className="col-5">
                              <b>Invested</b>
                            </div>
                            <div className="col-7 text-right">
                              ${item.invested === null ? "" : item.invested.toFixed(4)}
                            </div>  
                          </div>

                          <div className="row">
                            <div className="col-5">
                              <b>Entry Price</b>
                            </div>
                            <div className="col-7 text-right">
                              ${item.entry_average_price.toFixed(item.price_round_number)}
                            </div>  
                          </div>

                          <div className="row">
                            <div className="col-5">
                              <b>Exit Price</b>
                            </div>
                            <div className="col-7 text-right">
                              ${item.exit_price.toFixed(item.price_round_number)}
                            </div>  
                          </div>

                          <div className="row">
                            <div className="col-5">
                              <b>Net Profit</b>
                            </div>
                            <div className="col-7 text-right">
                              ${item.net_profit === null ? "" : item.net_profit.toFixed(4)}
                            </div>  
                          </div>

                          <div className="row">
                            <div className="col-5">
                              <b>Net Profit</b>
                            </div>
                            <div className="col-7 text-right">
                              <p className="mb-0 wspace-no">
                                <i
                                className={item.net_profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                aria-hidden="true"
                                />
                                {item.net_profit_percent === null ? "" : item.net_profit_percent.toFixed(2)}%
                              </p>
                            </div>  
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="d-none d-sm-block">
                      <table
                        className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                        id="marketCapital"
                        role="grid"
                        aria-describedby="example6_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Pair</th>
                            <th>Invested</th>
                            <th>Entry Price</th>
                            <th>Exit Price</th>
                            {/* <th>Profit</th>
                            <th>Profit, %</th>
                            <th>Fees</th> */}
                            <th>Net Profit</th>
                            <th>Net Profit, %</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          {closedPositions &&
                            closedPositions.map((item, key) => (
                              <tr key={key} role="row" className="odd">
                                <td className="wspace-no sorting_1">
                                <span>{convertTimestampToYmdHis(item.entry_timestamp)} ({timezone})</span><br/>
                                <small>{convertTimestampToYmdHisUTC(item.entry_timestamp)} (UTC)</small>
                                </td>
                                <td className="wspace-no sorting_1">
                                  <span>{convertTimestampToYmdHis(item.exit_timestamp)} ({timezone})</span><br/>
                                  <small>{convertTimestampToYmdHisUTC(item.exit_timestamp)} (UTC)</small>
                                </td>
                                <td className="wspace-no">
                                  {activeIntegrationPlatform === "binance" && (
                                    <span onClick={() => addNewChart(item, 'closed')} style={{opacity: loadingChart === true ? 0.4 : 1}}>
                                      <img alt="" src="./images/binance_logo.png" width="25px" />
                                      <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline">{item.pair}</a>
                                    </span>
                                  )}
                                  {activeIntegrationPlatform === "bybit" && (
                                    <span>
                                      <img alt="" src="./images/bybit_logo.png" width="25px" />
                                      <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline">{item.pair}</a>
                                    </span>
                                  )}
                                </td>
                                <td>${item.invested === null ? "" : item.invested.toFixed(4)}</td>
                                <td>${item.entry_average_price.toFixed(item.price_round_number)}</td>
                                <td>${item.exit_price.toFixed(item.price_round_number)}</td>
                                {/* <td>{item.profit === null ? "" :  item.profit.toFixed(4)}</td>
                                <td>
                                  <p className="mb-0 wspace-no">
                                    <i
                                      className={item.profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                      aria-hidden="true"
                                    />
                                    {item.profit_percent === null ? "" : item.profit_percent.toFixed(2)}%
                                  </p>
                                </td>
                                <td>{item.total_commission_usdt === null ? "" : item.total_commission_usdt.toFixed(10)}</td> */}
                                <td>${item.net_profit === null ? "" : item.net_profit.toFixed(4)}</td>
                                <td>
                                  <p className="mb-0 wspace-no">
                                    <i
                                      className={item.net_profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                      aria-hidden="true"
                                    />
                                    {item.net_profit_percent === null ? "" : item.net_profit_percent.toFixed(2)}%
                                  </p>
                                </td>
                              </tr>
                            )
                          )}

                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            )}

            {activaTableName === 'open' && (
              <div id="example6_wrapper" className="dataTables_wrapper no-footer">

                {loadingOpenPositions === true && (
                  <Badge variant="info light">Loading...</Badge>
                )}

                {loadingOpenPositions === false && openPositions && openPositions.length === 0 && (
                  <Alert variant="primary" className="alert-dismissible fade show">
                    There are no open positions at this moment.
                  </Alert>
                )}

                {loadingOpenPositions === false && openPositions && openPositions.length > 0 && (
                  <>
                    <div className="d-block d-sm-none">
                      {openPositions &&
                        openPositions.map((item, key) => (

                          <div className="card p-3 text-black">
                            <div className="row">
                              <div className="col-5">
                                <b>Start Date</b>
                              </div>
                              <div className="col-7 text-right">
                                <span>{convertTimestampToYmdHis(item.entry_timestamp)} ({timezone})</span><br/>
                                <small>{convertTimestampToYmdHisUTC(item.entry_timestamp)} (UTC)</small>
                              </div>
                            </div>
                            <hr/>

                            <div className="row">
                              <div className="col-5">
                                <b>Pair</b>
                              </div>
                              <div className="col-7 text-right">
                                {activeIntegrationPlatform === "binance" && (
                                  <span onClick={() => addNewChart(item, 'open')} style={{opacity: loadingChart === true ? 0.4 : 1}}>
                                    <img alt="" src="./images/binance_logo.png" width="25px" />
                                    <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline" style={{textDecoration: 'underline'}}>{item.pair}</a>
                                  </span>
                                )}
                                {activeIntegrationPlatform === "bybit" && (
                                  <span>
                                    <img alt="" src="./images/bybit_logo.png" width="25px" />
                                    <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline">{item.pair}</a>
                                  </span>
                                )}
                              </div>  
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>Invested</b>
                              </div>
                              <div className="col-7 text-right">
                                ${item.invested === null ? "" : item.invested.toFixed(4)}
                              </div>  
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>Entry Price</b>
                              </div>
                              <div className="col-7 text-right">
                                ${item.entry_avg_price.toFixed(item.price_round_number)}
                              </div>  
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>Current Price</b>
                              </div>
                              <div className="col-7 text-right">
                                ${item.current_price.toFixed(item.price_round_number)}
                              </div>  
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>TP Price</b>
                              </div>
                              <div className="col-7 text-right">
                                ${item.take_profit_price.toFixed(item.price_round_number)}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>DCA</b>
                              </div>
                              <div className="col-7 text-right">
                                {item.dca_available === 1 && (
                                  <b className="text-success">Yes</b>
                                )}
                                {item.dca_available === 0 && (
                                  <b className="text-info">No</b>
                                )}
                              </div>  
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>DCA Price</b>
                              </div>
                              <div className="col-7 text-right">
                                {item.dca_target_price && (
                                  <span>${item.dca_target_price.toFixed(item.price_round_number)}</span>
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>SL Price</b>
                              </div>
                              <div className="col-7 text-right">
                                {item.dca_available === 1 && item.dca_completed === 1 && (
                                  <span>${item.stop_loss_price}</span>
                                )}
                                {item.dca_available === 1 && item.dca_completed === 0 && (
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="top"
                                    responsive={true}
                                    className="primary"
                                    overlay={
                                      <Popover
                                        id={`popover-positioned-top`}
                                      >
                                        <Popover.Content>
                                          The Stop Loss Price will be calculated if the DCA Target price is reached.
                                        </Popover.Content>
                                      </Popover>
                                    }
                                  >
                                    {/* <Button variant="info" size="xs" className="">
                                      ?
                                    </Button> */}
                                    {/* <Badge variant="info light">?</Badge> */}
                                    <b className="text-info">?</b>
                                  </OverlayTrigger>
                                )}
                                {item.dca_available === 0 && (
                                  <span>${item.stop_loss_price === null ? "" : item.stop_loss_price.toFixed(4)}</span>
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>Profit</b>
                              </div>
                              <div className="col-7 text-right">
                                ${item.profit === null ? "" : item.profit.toFixed(4)}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-5">
                                <b>Profit, %</b>
                              </div>
                              <div className="col-7 text-right">
                                <p className="mb-0 wspace-no">
                                  <i
                                    className={item.profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                    aria-hidden="true"
                                  />
                                  {item.profit_percent === null ? "" : item.profit_percent.toFixed(2)}%
                                </p>
                              </div>
                            </div>

                          </div>
                        )
                      )}
                    </div>

                    <div className="d-none d-sm-block">
                      <table
                        className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                        id="marketCapital"
                        role="grid"
                        aria-describedby="example6_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Start Date</th>
                            <th>Pair</th>
                            <th>Invested</th>
                            <th>Entry Price</th>
                            <th>Current Price</th>
                            <th>Take Profit Price</th>
                            <th>DCA</th>
                            <th>DCA Target Price</th>
                            <th>Stop Loss Price</th>
                            <th>Profit</th>
                            <th>Profit, %</th>
                          </tr>
                        </thead>
                        <tbody>
                          {openPositions &&
                            openPositions.map((item, key) => (
                              <tr key={key} role="row" className="odd">
                                <td className="wspace-no sorting_1">
                                  <span>{convertTimestampToYmdHis(item.entry_timestamp)} ({timezone})</span><br/>
                                  <small>{convertTimestampToYmdHisUTC(item.entry_timestamp)} (UTC)</small>
                                </td>
                                <td className="wspace-no">
                                  {/* <i className="text-info flaticon-381-controls-7"></i> */}
                                  {/* <img alt="" src="./images/binance_logo.png" width="25px" />
                                  <a href={item.binance_url} target="_blank" rel="noreferrer" className="text-black pl-2 hover_underline">{item.pair}</a> */}

                                  {activeIntegrationPlatform === "binance" && (
                                    <span onClick={() => addNewChart(item, 'open')} style={{opacity: loadingChart === true ? 0.4 : 1}}>
                                      <img alt="" src="./images/binance_logo.png" width="25px" />
                                      <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline">{item.pair}</a>
                                    </span>
                                  )}
                                  {activeIntegrationPlatform === "bybit" && (
                                    <span>
                                      <img alt="" src="./images/bybit_logo.png" width="25px" />
                                      <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline">{item.pair}</a>
                                    </span>
                                  )}

                                </td>
                                <td>${item.invested === null ? "" : item.invested.toFixed(4)}</td>
                                <td>${item.entry_avg_price.toFixed(item.price_round_number)}</td>
                                <td>${item.current_price.toFixed(item.price_round_number)}</td>
                                <td>${item.take_profit_price.toFixed(item.price_round_number)}</td>
                                <td>
                                  {item.dca_available === 1 && (
                                    <Badge variant="success light">Yes</Badge>
                                  )}
                                  {item.dca_available === 0 && (
                                    <Badge variant="info light">No</Badge>
                                  )}
                                </td>
                                <td>${item.dca_target_price.toFixed(item.price_round_number)}</td>
                                <td>
                                  {item.dca_available === 1 && item.dca_completed === 1 && (
                                    <span>${item.stop_loss_price.toFixed(item.price_round_number)}</span>
                                  )}
                                  {item.dca_available === 1 && item.dca_completed === 0 && (
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="top"
                                      responsive={true}
                                      className="primary"
                                      overlay={
                                        <Popover
                                          id={`popover-positioned-top`}
                                        >
                                          <Popover.Content>
                                            The Stop Loss Price will be calculated if the DCA Target price is reached.
                                          </Popover.Content>
                                        </Popover>
                                      }
                                    >
                                      <Button variant="info" size="xs" className="">
                                        ?
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                                  {item.dca_available === 0 && (
                                    <span>${item.stop_loss_price === null ? "" : item.stop_loss_price.toFixed(4)}</span>
                                  )}
                                </td>
                                <td>${item.profit === null ? "" : item.profit.toFixed(4)}</td>
                                <td>
                                  <p className="mb-0 wspace-no">
                                    <i
                                      className={item.profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                      aria-hidden="true"
                                    />
                                    {item.profit_percent === null ? "" : item.profit_percent.toFixed(2)}%
                                  </p>
                                </td>
                              </tr>
                            )
                          )}

                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            )}

          </div>

          {loadingClosedPositions === false && activaTableName === 'closed' && (
            <div className="">
              <Pagination
                size="sm"
                className={`mt-4 pagination-gutter pagination-primary no-bg pagination-circle`}
              >
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePagePrev()}>
                    <i className="la la-angle-left" />
                  </Link>
                </li>

                {pageItems.length > 0 &&
                  pageItems.map((item, i) => (
                    <Pagination.Item key={i} active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                      {item.p}
                    </Pagination.Item>
                  )
                )}
                
                <li className="page-item page-indicator">
                  <Link className="page-link" to="#" onClick={() => changePageNext()}>
                    <i className="la la-angle-right" />
                  </Link>
                </li>
              </Pagination>

              {loadingBadge === true && (
                <div className="mt-3">
                  <Badge variant="info light">Loading</Badge>
                </div>
              )}
              
            </div>
          )}

        </div>
      </div>
    </>
  );
};

export default Statistics;
