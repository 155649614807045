import React, { useContext, useEffect, useState } from "react";
import { Nav, Alert, Modal, Button } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";

import pattern1 from "../../../images/pattern/pattern1.png";
import pattern2 from "../../../images/pattern/pattern2.png";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import Positions from './Positions';

import {SetBackgroundTheme} from "../../setBackgroundTheme";
import loadingGif from "../../../images/loading.gif";

const Dashboard = ({ accountDetails }) => {
  const [freeTrialActive, setFreeTrialActive] = useState(true);
  const [basicModal, setBasicModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tradingCapital, setTradingCapital] = useState(10000);
  const [tradingCapitalStatistic, setTradingCapitalStatistic] = useState(0);
  

  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState('');
  const [disabledSaveDemoButton, setDisabledSaveDemoButton] = useState(false);
  
  

  const [totalProfit, setTotalProfit] = useState(0);
  const [, setTotalVolume] = useState(0);
  const [totalInvested, setTotalInvested] = useState(0);
  const [netProfitPercent, setNetProfitPercent] = useState(0);
  const [activeChart, setActiveChart] = useState('week');

  const [chartDataValues, setChartDataValues] = useState([]);
  const [chartDataPercentValues, setChartDataPercentValues] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    const timestamp = Math.floor(Date.now() / 1000);
    if(accountDetails.free_trial_end_timestamp !== undefined) {
      setFreeTrialActive(timestamp <= accountDetails.free_trial_end_timestamp);
    }

    getTradingAccountSettings();
    getMainStatistic();
    getChartData();
    checkUserAuth();

    SetBackgroundTheme(changeBackground, Cookies);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line
  }, [activeChart]);

  let path = window.location.pathname;
  path = path.split("/");
  var location = path[path.length - 1];
  location = location.split("?")[0];
  
  async function checkUserAuth() {
    const token = Cookies.get('token');
    const data = { token: token }
    const response = await axios.post(baseURL+'/api/user/auth_check', data)
    if(response.data.status === 'ok') {
        Cookies.set('token', response.data.token, { expires: 1, path: '/'});

        if(location !== 'page-confirm-email' && location !== 'page-confirm-email-checking') {
          if(response.data.email_verified_at === null) {
            window.location.href = '/page-confirm-email';
            return "";
          }
        }

        if(location === 'page-login' || location === 'page-register' || location === 'page-forgot-password') {
          window.location.href = '/';
        }
    } else {
        if(location !== 'page-login' && location !== 'page-register' && location !== 'page-forgot-password' && location !== 'page-confirm-email' && location !== 'page-confirm-email-checking' && location !== 'page-password-reset') {
          window.location.href = '/page-login';
        }
    }
  }

  async function getTradingAccountSettings() {
    const data = { token: Cookies.get('token') }

    try {
      const response = await axios.post(baseURL+'/api/demo_exchange/get_settings', data);
      if(response.data.status === 'ok') {
        setFirstName(response.data.first_name);
        setLastName(response.data.last_name);
        setTradingCapital(response.data.invested_max);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
  }

  async function saveDemoAccountSettings() {
    setDisabledSaveDemoButton(true);
    const data = {
      token: Cookies.get('token'),
      first_name: firstName,
      last_name: lastName,
      invested_max: tradingCapital
    }

    try {
      const response = await axios.post(baseURL+'/api/demo_exchange/update_settings', data);
      if(response.data.status === 'ok') {
        setBasicModal(false);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setDisabledSaveDemoButton(false);
  }

  async function getMainStatistic() {
    const data = {token: Cookies.get('token')}

    try {
      const response = await axios.post(baseURL+'/api/demo_exchange/trading/get_main_statistics', data);
      if(response.data.status === 'ok') {
        setTotalProfit(response.data.net_profit);
        setTotalVolume(response.data.volume);
        setTotalInvested(response.data.total_invested);
        setNetProfitPercent(response.data.net_profit_percent);
        setTradingCapitalStatistic(response.data.trading_capital);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
  }

  async function getChartData() {
    const data = {
      token: Cookies.get('token'),
      // chart_type: activeChart
      chart_type: "day"
    }

    try {
      const response = await axios.post(baseURL+'/api/demo_exchange/trading/get_chart_data', data);
      if(response.data.status === 'ok') {
        setChartDataValues(response.data.data_values);
        setChartDataPercentValues(response.data.data_percent_values);
        setChartLabels(response.data.labels);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
  }

  const chartData = {
    defaultFontFamily: "poppins",
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    labels: chartLabels,
    datasets: [
      {
        yAxisID: 'first',
        label: "USDT",
        // data: [65, -59, 80, -81, 56, -55, 40, -55, 40],
        data: chartDataValues,
        // borderColor: "rgba(64, 24, 157, 0)",
        borderWidth: "0",
        backgroundColor: setBarColor,
      },
      {
        yAxisID: 'second',
        label: "%",
        // data: [3.75, -1, 2, -3, 4, -5, 6, -7, 7],
        data: chartDataPercentValues,
        // borderColor: "rgba(64, 24, 157, 0)",
        borderWidth: "0",
        backgroundColor: '#00a8ff',
      },
    ]
  };

  function setBarColor(value) {
    if (value.dataset.data[value.dataIndex] > 0) {
      return "#61C277";
    } else {
      return "#FF3E3E";
    }
  }

  const chartOptions = {
    legend: false,
    scales: {
      yAxes: [
        {
          id: 'first',
          ticks: {
            display: true,
            beginAtZero: true,
            callback: function(value, index, values) {
              // Add label before each value
              return '$ ' + value;
            },
            // suggestedMin: Math.min(...chartDataValues, ...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataValues, ...chartDataPercentValues)
            suggestedMin: getSuggestedMin(chartDataValues),
            suggestedMax: getSuggestedMax(chartDataValues),

          },
          gridLines: {
            display: false
          },
          position: 'left',
        },
        {
          id: 'second',
          ticks: {
            display: true,
            beginAtZero: true,
            callback: function(value, index, values) {
              // Add label before each value
              return value + '%';
            },
            // suggestedMin: Math.min(...chartDataValues, ...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataValues, ...chartDataPercentValues)
            // suggestedMin: Math.min(...chartDataPercentValues),
            // suggestedMax: Math.max(...chartDataPercentValues)
          },
          gridLines: {
            display: false
          },
          position: 'right',
        },
      ],
      xAxes: [
        {
          // Change here
          barPercentage: 0.5,
          ticks: {
            display: true,
            beginAtZero: true,
          },
          gridLines: {
            display: false
          },
        },
      ],
    },
  };

  function getSuggestedMin(data) {
    const min = Math.min(...data);
    const max = Math.max(...data);

    const min_abs = Math.abs(min);
    const max_abs = Math.abs(max);

    var result_min = 0;
    if(min_abs > max_abs) {
      result_min = min_abs;
    } else {
      result_min = max_abs;
    }

    if(min < 0) {
      result_min = result_min * (-1)
    }

    return result_min;
  }

  function getSuggestedMax(data) {
    const min = Math.min(...data);
    const max = Math.max(...data);

    const min_abs = Math.abs(min);
    const max_abs = Math.abs(max);

    var result_max = 0;
    if(min_abs > max_abs) {
      result_max = min_abs;
    } else {
      result_max = max_abs;
    }

    return result_max;
  }

  return (
    <div className="row">
      {/* <SetBackgroundTheme /> */}

      {freeTrialActive === false && (
        <div className="col-12">
          <Alert variant="danger" className="alert-dismissible fade show">
            <span><b>Free trial ended.</b> Please contact out support team.</span>
          </Alert>
        </div>
      )}

      <div className="col-12">
        <Alert variant="primary" className="alert-dismissible fade show">
          <span><b>Demo account connected successfully.</b> As soon as our trading algorithm sends the signal, it will open the first position.</span><br/>
          <button onClick={() => setBasicModal(true)} className="btn btn-xs btn-outline-primary">Change Settings</button>
        </Alert>
      </div>

      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Change Settings</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setBasicModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          
          <div className="basic-form">

              <div className="form-group row">
                <label className="col-sm-12 col-form-label">First Name</label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-12 col-form-label">Last Name</label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-12 col-form-label">Demo Trading Capital, USDT</label>
                <div className="col-sm-12">
                  <select className="form-control" value={tradingCapital} onChange={(e) => setTradingCapital(e.target.value)}>
                    <option value={10000} selected={tradingCapital === 10000 ? true : false}>10 000</option>
                    <option value={50000} selected={tradingCapital === 50000 ? true : false}>50 000</option>
                    <option value={100000} selected={tradingCapital === 100000 ? true : false}>100 000</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label"></label>
                <div className="col-sm-12">
                  <button className="btn btn-md btn-block btn-info" disabled={disabledSaveDemoButton} onClick={() => saveDemoAccountSettings()}>
                    {disabledSaveDemoButton === false && (
                      <span>Save Settings</span>
                    )}
                    {disabledSaveDemoButton === true && (
                      <span><img alt="" src={loadingGif} width="18" /></span>
                    )}
                  </button>

                  {error !== "" && (
                    <Alert variant="danger" className="mt-3 alert-dismissible fade show">
                      {error}
                    </Alert>
                  )}

                </div>
              </div>

          </div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            onClick={() => setBasicModal(false)}
            variant="danger light"
          >
            Close
          </Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal>

      <div className="col-12 p-0 mb-4">
        <div className="row">
          <div className="col-12 col-sm-6 mb-2">
            <div className="items">
              <div
                className="wallet-card bg-info"
                style={{ backgroundImage: `url(${pattern1})` }}
              >
                <div className="head">
                  <p className="fs-14 text-white mb-0 op6 font-w100">Total Profit, USDT</p>
                  <span>
                    ${totalProfit}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 mb-2">
            <div className="items">
              <div
                className="wallet-card bg-primary"
                style={{ backgroundImage: `url(${pattern2})` }}
              >
                <div className="head">
                  {/* <p className="fs-14 text-white mb-0 op6 font-w100">Total Invested, USDT</p>
                  <span>${totalInvested}</span> */}

                  <p className="fs-14 text-white mb-0 op6 font-w100">Trading Capital, USDT</p>
                  <span>${tradingCapitalStatistic}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="col-12">
        <div className="card overflow-hidden">
          <div className="card-header d-block d-sm-flex border-0">
            <div>
              <h4 className="fs-20 text-black">PnL, USDT</h4>
              <p className="mb-0 fs-12">
                The Breakdown of Profit
              </p>
            </div>
          </div>
          <div className="card-body">
            <Bar data={chartData} height={100} options={chartOptions} />
          </div>
        </div>
      </div>

      <div className="col-12">
        <Positions />
      </div>

    </div>
  );
};

export default Dashboard;
