import React, { useState, useEffect, useContext } from "react";
import { Dropdown, Badge, Popover, OverlayTrigger, Button, Pagination, Alert, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

import {SetBackgroundTheme} from "../../setBackgroundTheme";

const RecentClosedPositions = () => {
  const [activaTableName, setActivaTableName] = useState('closed');
  const [closedPositions, setClosedPositions] = useState([]);
  const [openPositions, setOpenPositions] = useState([]);

  const [loadingClosedPositions, setLoadingClosedPositions] = useState(true);
  const [loadingOpenPositions, setLoadingOpenPositions] = useState(true);
  

  const [active, setActive] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const [pageItems, setPageItems] = useState([{p:1}]);
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [maxAvailablePage, setMaxAvailablePage] = useState(1);

  const [themeName, setThemeName] = useState('light');
  const [timezone, setTimezone] = useState('');
  
  const { changeBackground, background } = useContext(ThemeContext);
  useEffect(() => {
    const offset = getTimezoneOffsetInHours();
    const timezone_in_utc = `UTC${offset >= 0 ? '+' : ''}${offset}`
    setTimezone(timezone_in_utc);

    getClosedPositions(1);
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  useEffect(() => {
    setThemeName(background.value);
  }, [background]);

  

  function setNavigationLinks(max_page) {
    var page_items_tmp = [];
    var index = 0;
    for (let number = active; number <= max_page; number++) {
        index += 1;
        if(index >= 7) {
          break;
        }
        page_items_tmp.push({p:number});
    }
    setPageItems(page_items_tmp);
  }

  function changePage(page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    var max_page = maxPage;
    if(page_number > 5) {
      number_first = page_number - 5;
      max_page = max_page + number_first;
      if(max_page > maxAvailablePage){
        max_page = maxAvailablePage;
      }
    }

    var page_items_tmp = [];
    var index = 0;
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
        index += 1;
        if(index >= 7) {
          break;
        }
    }
    setPageItems(page_items_tmp);
    getClosedPositions(page_number);
  }

  function changePageNoReload(page, max_page) {
    setLoadingBadge(true);
    var page_number = parseInt(page);
    setActive(page_number);

    var number_first = 1;
    if(page_number > 5) {
        number_first = page_number - 5;
        max_page = max_page + number_first;
    }

    var page_items_tmp = [];
    var index = 0;
    for (let number = number_first; number <= max_page; number++) {
        page_items_tmp.push({p:number});
        index += 1;
        if(index >= 7) {
          break;
        }
    }
    setPageItems(page_items_tmp);
  }

  function changePagePrev() {
    var next_page = active - 1;
    if(next_page < 1) {
      next_page = 1;
    }
    setActive(next_page);
    changePage(next_page);
  }

  function changePageNext() {
    const next_page = active + 1;
    if(next_page <= maxAvailablePage) {
      setActive(next_page);
      changePage(next_page);
    }
  }

  async function getClosedPositions(page_number) {
    setLoadingBadge(true);
    const data = {
      token: Cookies.get('token'),
      page: page_number
    }

    try {
      const response = await axios.post(baseURL+'/api/positions/get_recent_closed_positions', data);
      if(response.data.status === 'ok') {
        setClosedPositions(response.data.positions);
        setNavigationLinks(response.data.max_page);
        setMaxAvailablePage(response.data.max_page);
        setMaxPage(response.data.max_page);
        changePageNoReload(page_number, response.data.max_page);
      } else {
        // setError(response.data.text);
      }
    } catch(err) {

    }
    setLoadingBadge(false);
    setLoadingClosedPositions(false);
  }

  const getTimezoneOffsetInHours = () => {
    const offset = new Date().getTimezoneOffset();
    const offset_hour = -offset / 60;
    // console.log("offset_hour: ", offset_hour);
    return -offset / 60; // Convert minutes to hours
  };

  const getTimezoneName = () => {
    const dateTimeFormat = new Intl.DateTimeFormat();
    const options = dateTimeFormat.resolvedOptions();
    return options.timeZone; // Returns the IANA timezone string, e.g., "America/Chicago"
  };

  function convertTimestampToYmdHis(timestamp) {
    const offset = getTimezoneOffsetInHours();
    const timezone_in_utc = `UTC${offset >= 0 ? '+' : ''}${offset}`
    const timezone_name = getTimezoneName();

    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone_name
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;

    // Convert timestamp to Date object
    // let date = new Date(timestamp * 1000);

    // // Get the year, month, day, hours, minutes, and seconds
    // let year = date.getFullYear();
    // let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    // let day = String(date.getDate()).padStart(2, '0');
    // let hours = String(date.getHours()).padStart(2, '0');
    // let minutes = String(date.getMinutes()).padStart(2, '0');
    // let seconds = String(date.getSeconds()).padStart(2, '0');

    // // Construct the formatted date string
    // let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    // return formattedDate;
  };

  function convertTimestampToYmdHisUTC(timestamp) {
    // Create a new Date object from the timestamp
    // const date = new Date(timestamp * 1000);

    // // Extract the date components
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);
    // const hours = ('0' + date.getHours()).slice(-2);
    // const minutes = ('0' + date.getMinutes()).slice(-2);
    // const seconds = ('0' + date.getSeconds()).slice(-2);

    // // Construct the formatted date string
    // const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    // return formattedDate;

    // Convert timestamp to Date object
    let date = new Date(timestamp * 1000);

    // Define options for formatting the date
    let options = { 
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'UTC' 
    };

    // Format the date
    let formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  return (
    <>
      <div className="col-12">

        <div className="d-block d-sm-flex border-0 mt-0 pt-0 pb-3">
          <div>
            <h4 className="fs-20 text-black">Recently Closed Positions</h4>
            <p className="mb-0 fs-12">
              of other users
            </p>
          </div>
        </div>
                

        <div className="table-responsive table-hover fs-14 ">

          <div id="example6_wrapper" className="dataTables_wrapper no-footer">

            {loadingClosedPositions === true && (
              <Badge variant="info light">Loading...</Badge>
            )}

            {loadingClosedPositions === false && closedPositions.length === 0 && (
              <Alert variant="primary" className="alert-dismissible fade show">
                There are no closed positions at this moment.
              </Alert>
            )}
            {loadingClosedPositions === false && closedPositions.length > 0 && (
              <>
                <div className="d-block d-sm-none">
                {closedPositions &&
                  closedPositions.map((item, key) => (
                    <div className="card p-3 text-black">
                      <div className="row">
                        <div className="col-5">
                          <b>Start Date</b>
                        </div>
                        <div className="col-7 text-right">
                          <span>{convertTimestampToYmdHis(item.entry_timestamp)} ({timezone})</span><br/>
                          <small>{convertTimestampToYmdHisUTC(item.entry_timestamp)} (UTC)</small>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-5">
                          <b>End Date</b>
                        </div>
                        <div className="col-7 text-right">
                          <span>{convertTimestampToYmdHis(item.exit_timestamp)} ({timezone})</span><br/>
                          <small>{convertTimestampToYmdHisUTC(item.exit_timestamp)} (UTC)</small>
                        </div>
                      </div>
                      <hr/>

                      <div className="row">
                        <div className="col-5">
                          <b>Pair</b>
                        </div>
                        <div className="col-7 text-right">
                          <span>
                            {/* <img alt="" src="./images/binance_logo.png" width="25px" /> */}
                            <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline" style={{textDecoration: 'underline'}}>{item.pair}</a>
                          </span>
                        </div>  
                      </div>

                      <div className="row">
                        <div className="col-5">
                          <b>Invested</b>
                        </div>
                        <div className="col-7 text-right">
                          ${item.invested === null ? "" : item.invested.toFixed(4)}
                        </div>  
                      </div>

                      <div className="row">
                        <div className="col-5">
                          <b>Entry Price</b>
                        </div>
                        <div className="col-7 text-right">
                          ${item.entry_average_price.toFixed(item.price_round_number)}
                        </div>  
                      </div>

                      <div className="row">
                        <div className="col-5">
                          <b>Exit Price</b>
                        </div>
                        <div className="col-7 text-right">
                          ${item.exit_price.toFixed(item.price_round_number)}
                        </div>  
                      </div>

                      <div className="row">
                        <div className="col-5">
                          <b>Net Profit</b>
                        </div>
                        <div className="col-7 text-right">
                          ${item.net_profit === null ? "" : item.net_profit.toFixed(4)}
                        </div>  
                      </div>

                      <div className="row">
                        <div className="col-5">
                          <b>Net Profit</b>
                        </div>
                        <div className="col-7 text-right">
                          <p className="mb-0 wspace-no">
                            <i
                            className={item.net_profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                            aria-hidden="true"
                            />
                            {item.net_profit_percent === null ? "" : item.net_profit_percent.toFixed(2)}%
                          </p>
                        </div>  
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-none d-sm-block">
                  <table
                    className="table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer"
                    id="marketCapital"
                    role="grid"
                    aria-describedby="example6_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Pair</th>
                        <th>Invested</th>
                        <th>Entry Price</th>
                        <th>Exit Price</th>
                        {/* <th>Profit</th>
                        <th>Profit, %</th>
                        <th>Fees</th> */}
                        <th>Net Profit</th>
                        <th>Net Profit, %</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      {closedPositions &&
                        closedPositions.map((item, key) => (
                          <tr key={key} role="row" className="odd">
                            <td className="wspace-no sorting_1">
                            <span>{convertTimestampToYmdHis(item.entry_timestamp)} ({timezone})</span><br/>
                            <small>{convertTimestampToYmdHisUTC(item.entry_timestamp)} (UTC)</small>
                            </td>
                            <td className="wspace-no sorting_1">
                              <span>{convertTimestampToYmdHis(item.exit_timestamp)} ({timezone})</span><br/>
                              <small>{convertTimestampToYmdHisUTC(item.exit_timestamp)} (UTC)</small>
                            </td>
                            <td className="wspace-no">
                              <span>
                                <img alt="" src="./images/binance_logo.png" width="25px" />
                                <a href={`#chart_(${item.id})`} className="text-black pl-2 hover_underline">{item.pair}</a>
                              </span>
                            </td>
                            <td>${item.invested === null ? "" : item.invested.toFixed(4)}</td>
                            <td>${item.entry_average_price.toFixed(item.price_round_number)}</td>
                            <td>${item.exit_price.toFixed(item.price_round_number)}</td>
                            {/* <td>{item.profit === null ? "" :  item.profit.toFixed(4)}</td>
                            <td>
                              <p className="mb-0 wspace-no">
                                <i
                                  className={item.profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                  aria-hidden="true"
                                />
                                {item.profit_percent === null ? "" : item.profit_percent.toFixed(2)}%
                              </p>
                            </td>
                            <td>{item.total_commission_usdt === null ? "" : item.total_commission_usdt.toFixed(10)}</td> */}
                            <td>${item.net_profit === null ? "" : item.net_profit.toFixed(4)}</td>
                            <td>
                              <p className="mb-0 wspace-no">
                                <i
                                  className={item.net_profit_percent >= 0 ? "fa fa-caret-up scale5 mr-2 text-success" : "fa fa-caret-down scale5 mr-2 text-danger"}
                                  aria-hidden="true"
                                />
                                {item.net_profit_percent === null ? "" : item.net_profit_percent.toFixed(2)}%
                              </p>
                            </td>
                          </tr>
                        )
                      )}

                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>

        </div>

        {/* {loadingClosedPositions === false && activaTableName === 'closed' && (
          <div className="">
            <Pagination
              size="sm"
              className={`mt-4 pagination-gutter pagination-primary no-bg pagination-circle`}
            >
              <li className="page-item page-indicator">
                <Link className="page-link" to="#" onClick={() => changePagePrev()}>
                  <i className="la la-angle-left" />
                </Link>
              </li>

              {pageItems.length > 0 &&
                pageItems.map((item, i) => (
                  <Pagination.Item key={i} active={item.p === active ? true : false} onClick={() => {changePage(item.p)}}>
                    {item.p}
                  </Pagination.Item>
                )
              )}
              
              <li className="page-item page-indicator">
                <Link className="page-link" to="#" onClick={() => changePageNext()}>
                  <i className="la la-angle-right" />
                </Link>
              </li>
            </Pagination>

            {loadingBadge === true && (
              <div className="mt-3">
                <Badge variant="info light">Loading</Badge>
              </div>
            )}
            
          </div>
        )} */}

      </div>
    </>
  );
};

export default RecentClosedPositions;
