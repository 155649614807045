import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import {SetBackgroundTheme} from "../../setBackgroundTheme";
import axios from 'axios';
import { baseURL } from "../../../baseURL";
import Cookies from 'js-cookie';

const ExchangesConnect = () => {
  const [copiedIpToClipboard, setCopiedIpToClipboard] = useState(false);
  const [ip, setIp] = useState('');
  

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    SetBackgroundTheme(changeBackground, Cookies);
  }, []);

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(ip);
      setCopiedIpToClipboard(true);
      await delay(3000);
      setCopiedIpToClipboard(false);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  async function getTrustedIp() {
    const data = { token: Cookies.get('token') }
    try {
      const response = await axios.post(baseURL+'/api/get_trusted_ip', data);
      if(response.data.status === 'ok') {
        setIp(response.data.ip);
      } else {
        
      }
    } catch(err) {

    }
  }

  useEffect(() => {
    getTrustedIp();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Get Started</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <p>To get started using BETTR Invest, you will need a verified Bybit.com account.</p>
                  <p>Bybit sign-up link: <a href="https://www.bybit.com/invite?ref=JQPOXK" className="btn btn-sm btn-info" target="_blank" rel="noreferrer">https://www.bybit.com/</a></p>
                  <p>Note: Depositing on BETTR Invest is for paying invoices, not for trading!</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Video Instruction</h4>
            </div>
            <div className="card-body">
              <p className="text-left">Watch our step-by-step video guide to easily set up your Binance account and connect it to BettrTrade.</p>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/tn6bOisU058?si=r9DvhZG2Y9afMNd2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div> */}

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">API Key</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <h5>Once you have a Bybit account, create an API key:</h5>
                  <p>Step 1. Open the API Management section in your Bybit account: <a href="https://www.bybit.com/app/user/api-management" target="_blank" rel="noreferrer">API Management</a></p>
                  <p>Step 2. Click on the "Create New Key" button to create the API Key.</p>
                  <div class="row col-12 mb-3">
                    <img src="/images/bybit/create_api_key.png" width="100%" alt="" />
                  </div>
                  <p>Step 3. Select "System generated" type key.</p>
                  <div class="row col-12 mb-3">
                    <img src="/images/bybit/api_key_type.png" width="100%" alt="" />
                  </div>
                  <p>
                    Step 4. Make the API Settings<br/>
                    1. API Key Usage: API Transaction<br/>
                    2. Name for the API key: Enter any text<br/>
                    3. API Key Permissions: Read-Write<br/>
                    4. API Key Permissions: Only IPs with permissions granted are allowed to access the OpenAPI<br/>
                    5. API Key Permissions: Unified Trading (Read-Write: Orders, Positions, USDC Derivatives Trading, Trade)<br/>
                  </p>
                  <div class="row col-12 mb-3">
                    <img src="/images/bybit/api_settings.png" width="100%" alt="" />
                    <img src="/images/bybit/api_settings2.png" width="100%" alt="" />
                  </div>
                  <p>Step 5. Complete the verifications.</p>
                  <div class="row col-12 mb-3">
                    <img src="/images/bybit/complete_verification.png" width="100%" alt="" />
                  </div>
                  <p>Make sure withdrawl is not enabled and all other options match the image.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Connect Exchange</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                  <p>Once your API key is created, connect it on BETTR Invest under "Connect exchange", or go to:  <Link to="/exchanges/connect" class="btn btn-sm btn-info">Connect</Link></p>
                  <p>Once you've connected it, you're done!</p>
                  <p>Trading will begin shortly, and all statistics will display on your dashboard.</p>
                  <p>Remember, you must have at least <b>1000</b> USDT in your Bybit <b>Unified Trading</b> account!</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ExchangesConnect;
